.title-chains {
  padding-top: 30px;
  background-image: url('/assets/images/title-chains.png');
  background-repeat: no-repeat;
  background-position: center top;
}

.title-board {
  display: inline;
  background-image: url('/assets/images/title-board.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #eeeeee;
  font-size: 30px;
  min-width: 350px;
  padding: 5px 25px;
  background-position: center 5px;
  // text-shadow: 2px 2px #888888;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
}


.disabled-link {
  color: #aaa;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}
.disabled-link:after {
  font-family: "Font Awesome 5 Free"; font-weight: 400; content: "  \f070";
  display: inline;
}

.disabled-link:hover {
  text-decoration: line-through;
  cursor: pointer;
}