@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
html,
body {
  width: 100%;
  height: 100%;
}

.root-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.main-content {
  flex-grow: 1;
  /* min-height: calc(100vh - 300px); */
}

ea-heder,
main,
ea-footer {
  flex-shrink: 0;
}

.animals-sidebar-images {
  padding: 10px 15px;
}

.animal-image {
  max-width: 500px;
  max-height: 500px;
  -webkit-filter: drop-shadow(15px 15px 15px #222);
  filter: drop-shadow(15px 15px 15px #222);
}

.image-option {
  /* padding: .25rem; */
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.image-option:hover {
  cursor: pointer;
}

.image-option-wrong,
.image-option-correct {
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.image-option-wrong {
  background-color: #F44336;
}

.image-option-correct {
  background-color: #388e3c;
}

.quiz-question {
  filter: contrast(0%) brightness(50%);
}

.quiz-question.transition {
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  filter: contrast(100%) brightness(100%);
}

.quiz-question-title {
  opacity: 0;
  margin: 0;
}

.quiz-question-title.transition {
  -webkit-transition: all 1s linear;
  -moz-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
  opacity: 100;
}

.quiz-options-container {
  margin: 5px;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.animal-preview-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 710px;
  /* @todo go trough different sizes and set up min height depending of the device and its position (horisontal/vertical) */
  height: calc(100vh + 50px);
}

.p2 {
  padding: 10px;
}

.margin-b-1 {
  margin-bottom: 5px;
}

.margin-b-2 {
  margin-bottom: 10px;
}

.margin-r-1 {
  margin-right: 5px;
}

.margin-r-2 {
  margin-right: 10px;
}

.margin-t-1 {
  margin-top: 5px;
}

.margin-t-2 {
  margin-top: 10px;
}

.margin-t-3 {
  margin-top: 20px;
}

.margin-t-4 {
  margin-top: 30px;
}

@media only screen and (min-width: 601px) {
  nav a.sidenav-link,
nav a.sidenav-link i {
    height: 64px;
    line-height: 64px;
  }
}

nav .sidenav-link {
  float: left;
  position: relative;
  z-index: 1;
  height: 56px;
  margin: 0 18px;
}

.btn-nav-image {
  width: 80px !important;
  height: 80px !important;
  padding: 5px !important;
  border: 4px solid #666666;
}

.btn-nav-image-background {
  background-image: url("/assets/images/navigation-board.png");
  height: 90px;
  width: 100%;
  background-size: 100% 100%;
}

.footer {
  border: 1px solid transparent;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url("/assets/images/footer.png");
}

.paralax-wrapper {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 600px;
  z-index: 1;
  background-color: lightcyan;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-container-item {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.bubble1,
.bubble2,
.bubble3 {
  width: auto;
}

.bubble1 {
  text-align: center;
}

.bubble3 {
  text-align: right;
}

#bubble-cloud {
  position: relative;
  top: 0;
  transform: translateY(700px);
  -webkit-transform: translateY(700px);
  animation: bubble 10s linear infinite;
  -webkit-animation: bubble 10s linear infinite;
}
@media screen and (max-width: 600px) {
  #bubble-cloud {
    width: 150px;
  }
}

#bubble-cloud2 {
  position: relative;
  bottom: 0;
  transform: translateY(700px);
  -webkit-transform: translateY(700px);
  animation: bubble 25s linear infinite;
  -webkit-animation: bubble 25s linear infinite;
}
@media screen and (max-width: 600px) {
  #bubble-cloud2 {
    width: 350px;
  }
}

#bubble-cloud3 {
  position: relative;
  top: 0;
  transform: translateY(700px);
  -webkit-transform: translateY(700px);
  animation: bubble 15s linear infinite;
  -webkit-animation: bubble 15s linear infinite;
}
@media screen and (max-width: 600px) {
  #bubble-cloud3 {
    width: 250px;
  }
}

@keyframes bubble {
  to {
    transform: translateY(-600px);
  }
}
@-webkit-keyframes bubble {
  to {
    -webkit-transform: translateY(-600px);
  }
}
.title-chains {
  padding-top: 30px;
  background-image: url("/assets/images/title-chains.png");
  background-repeat: no-repeat;
  background-position: center top;
}

.title-board {
  display: inline;
  background-image: url("/assets/images/title-board.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #eeeeee;
  font-size: 30px;
  min-width: 350px;
  padding: 5px 25px;
  background-position: center 5px;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.5);
}

.disabled-link {
  color: #aaa;
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}

.disabled-link:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "  ";
  display: inline;
}

.disabled-link:hover {
  text-decoration: line-through;
  cursor: pointer;
}