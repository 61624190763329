$small: 600px;

.paralax-wrapper {
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 600px;
  z-index: 1;
  background-color: lightcyan;

  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-container-item {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

@import 'parallax-sea';
