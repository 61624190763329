.bubble1,
.bubble2,
.bubble3 {
  // position: relative;
  width: auto;
}

.bubble1 {
  text-align: center;
}

.bubble3 {
  text-align: right;
}

#bubble-cloud {
  position: relative;
  top: 0;

  transform: translateY(700px);
  -webkit-transform: translateY(700px);

  animation: bubble 10s linear infinite;
  -webkit-animation: bubble 10s linear infinite;


  @media screen and (max-width: $small) {
    width: 150px;
  }
}

#bubble-cloud2 {
  position: relative;
  bottom: 0;

  transform: translateY(700px);
  -webkit-transform: translateY(700px);

  animation: bubble 25s linear infinite;
  -webkit-animation: bubble 25s linear infinite;

  @media screen and (max-width: $small) {
    width: 350px;
  }
}


#bubble-cloud3 {
  position: relative;
  top: 0;

  transform: translateY(700px);
  -webkit-transform: translateY(700px);

  animation: bubble 15s linear infinite;
  -webkit-animation: bubble 15s linear infinite;

  @media screen and (max-width: $small) {
    width: 250px;
  }

}

@keyframes bubble {
  to {
    transform: translateY(-600px);
  }
}

@-webkit-keyframes bubble {
  to {
    -webkit-transform: translateY(-600px);
  }
}


// .container-test {
//   position: relative;
//   overflow: hidden;
//   height: 180px;
// }

// #cloud-img {
//   position: absolute;
//   width: 200px;
//   top:0;
//   left: 0;

//   transform: translateX(-200px);
//   -webkit-transform: translateX(-200px);

//   animation: cloud 2s linear infinite;
//   -webkit-animation: cloud 2s linear infinite;
// }

// @keyframes cloud { to { transform: translateX(100vw); }}
// @-webkit-keyframes cloud { to { -webkit-transform: translateX(100vw); }}
